<template>
  <!--begin::Sender-->
  <div>
    <!--begin::Catchphrase-->
    <div
      class="block1 row"
      style="background-image: url('media/landing/sender/fondo.jpg');"
    >
      <div class="col-lg-8 overlay-primary py-12 px-24">
        <p class="paragraph-white text-uppercase">
          {{ $t("LANDING.SENDER.BLOCK1.PRETITLE") }}
        </p>

        <div class="h1-white">
          {{ $t("LANDING.SENDER.BLOCK1.TITLE") }}
          <div class="tertiary-rectangle mt-3"></div>
        </div>

        <div class="paragraph-white mt-6 mb-18">
          {{ $t("LANDING.SENDER.BLOCK1.PARAGRAPH") }}
        </div>

        <div class="mb-18">
          <router-link to="/register/2">
            <button
              class="btn btn-pill button-form btn-tertiary text-uppercase px-12"
            >
              {{ $t("LANDING.SENDER.BLOCK1.BUTTON") }}
            </button>
          </router-link>
        </div>
      </div>
    </div>
    <!--end::Catchphrase-->

    <!--begin::Howto-->
    <div class="block5a row pb-6">
      <div class="col-lg-12">
        <div class="h1-white">
          {{ $t("LANDING.SENDER.BLOCK2.TITLE") }}
          <div class="tertiary-rectangle" style="margin: 0.75em auto;"></div>
        </div>
      </div>
    </div>
    <div
      class="block5b row px-18 mb-12"
      style="background-image: url('media/landing/index/bg-primary.png');"
    >
      <div class="col-lg-4 px-9 text-center">
        <v-img src="/media/landing/sender/carretera.png" class="w-100" />
        <div class="paragraph-primary mt-3 mb-1">
          <strong>{{ $t("LANDING.SENDER.BLOCK2.STEP1.TITLE") }}</strong>
        </div>
        <div class="paragraph-primary">
          {{ $t("LANDING.SENDER.BLOCK2.STEP1.PARAGRAPH") }}
        </div>
      </div>
      <div class="col-lg-4 px-9 text-center">
        <v-img src="/media/landing/sender/chofer.png" class="w-100" />
        <div class="paragraph-primary mt-3 mb-1">
          <strong>{{ $t("LANDING.SENDER.BLOCK2.STEP2.TITLE") }}</strong>
        </div>
        <div class="paragraph-primary">
          {{ $t("LANDING.SENDER.BLOCK2.STEP2.PARAGRAPH") }}
        </div>
      </div>
      <div class="col-lg-4 px-9 text-center">
        <v-img src="/media/landing/sender/empresa.png" class="w-100" />
        <div class="paragraph-primary mt-3 mb-1">
          <strong>{{ $t("LANDING.SENDER.BLOCK2.STEP3.TITLE") }}</strong>
        </div>
        <div class="paragraph-primary">
          {{ $t("LANDING.SENDER.BLOCK2.STEP3.PARAGRAPH") }}
        </div>
      </div>
    </div>
    <!--end::Howto-->

    <!--begin::Features-->
    <div class="block7 row">
      <div class="col-lg-12">
        <div class="h2-primary">
          {{ $t("LANDING.SENDER.BLOCK3.TITLE") }}
          <div class="tertiary-rectangle" style="margin: 0.75em auto;"></div>
        </div>
      </div>
      <div class="col-lg-3">
        <inline-svg src="/media/landing/sender/users.svg" class="block7-icon" />
        <div>
          {{ $t("LANDING.SENDER.BLOCK3.FEATURE1") }}
        </div>
      </div>
      <div class="col-lg-3">
        <inline-svg
          src="/media/landing/sender/fechas-entrega-recogida-copy.svg"
          class="block7-icon"
        />
        <div>
          {{ $t("LANDING.SENDER.BLOCK3.FEATURE2") }}
        </div>
      </div>
      <div class="col-lg-3">
        <inline-svg
          src="/media/landing/sender/fechas-entrega-recogida-copy-3.svg"
          class="block7-icon"
        />
        <div>
          {{ $t("LANDING.SENDER.BLOCK3.FEATURE3") }}
        </div>
      </div>
      <div class="col-lg-3">
        <inline-svg
          src="/media/landing/sender/fechas-entrega-recogida-copy-2.svg"
          class="block7-icon"
        />
        <div>
          {{ $t("LANDING.SENDER.BLOCK3.FEATURE4") }}
        </div>
      </div>
      <div class="col-lg-3">
        <inline-svg
          src="/media/landing/sender/fechas-entrega-recogida-copy-4.svg"
          class="block7-icon"
        />
        <div>
          {{ $t("LANDING.SENDER.BLOCK3.FEATURE5") }}
        </div>
      </div>
      <div class="col-lg-3">
        <inline-svg
          src="/media/landing/sender/fechas-entrega-recogida.svg"
          class="block7-icon"
        />
        <div>
          {{ $t("LANDING.SENDER.BLOCK3.FEATURE6") }}
        </div>
      </div>
      <div class="col-lg-3">
        <inline-svg
          src="/media/landing/sender/tarifas-clientes.svg"
          class="block7-icon"
        />
        <div>
          {{ $t("LANDING.SENDER.BLOCK3.FEATURE7") }}
        </div>
      </div>
      <div class="col-lg-3">
        <inline-svg
          src="/media/landing/sender/ratings.svg"
          class="block7-icon"
        />
        <div>
          {{ $t("LANDING.SENDER.BLOCK3.FEATURE8") }}
        </div>
      </div>
      <div class="col-lg-3">
        <inline-svg
          src="/media/landing/sender/seguimiento.svg"
          class="block7-icon"
        />
        <div>
          {{ $t("LANDING.SENDER.BLOCK3.FEATURE9") }}
        </div>
      </div>
      <div class="col-lg-3">
        <inline-svg
          src="/media/landing/sender/incidencias.svg"
          class="block7-icon"
        />
        <div>
          {{ $t("LANDING.SENDER.BLOCK3.FEATURE10") }}
        </div>
      </div>
      <div class="col-lg-3">
        <inline-svg src="/media/landing/sender/chat.svg" class="block7-icon" />
        <div>
          {{ $t("LANDING.SENDER.BLOCK3.FEATURE11") }}
        </div>
      </div>

      <div class="col-lg-12 pt-18 pb-9">
        <router-link to="/register/2">
          <button
            class="btn btn-pill button-form btn-tertiary text-uppercase px-12"
          >
            {{ $t("LANDING.SENDER.BLOCK4.BUTTON") }}
          </button>
        </router-link>
      </div>
    </div>
    <!--end::Features-->
  </div>
  <!--end::Sender-->
</template>

<script>
export default {
  name: "LandingSender",

  mounted() {
    this.$nextTick(() => {
      document.title = this.$t("LANDING.SENDER.BLOCK1.PRETITLE");
    });
  }
};
</script>
